import { ButtonType, ButtonVariant, ConsentFormType, EToastType } from 'types';
import { ETrackingAction, ETrackingEvent } from 'types/tracking';
import { IBulkSubscriptionType } from 'types/subscription';
import { modalWidthMedium, screenWidthMini, spaceL, spaceM, spaceS } from 'styles/variables';
import { sanitizeBulkSubscriptionOrders } from 'utils/subscription/sanitizeSubscriptionData';
import { scrollToTop } from 'utils/scrollToTop';
import { useCallback, useMemo } from 'react';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useForm } from 'react-hook-form';
import { useModal } from 'contexts/Modal';
import { useSearchParams } from 'react-router-dom';
import { useToast } from 'contexts/Toast';
import { useTranslation } from 'react-i18next';
import { validateContactsEmail } from 'utils/subscription/validateBulkSubscription';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import ConsentForm, { ConsentFormSchema } from 'components/consent/consentForm';
import ImportFileModal from 'components/subscriptions/ImportFileModal';
import NotificationMessage, { EInfoType } from 'components/info/NotificationMessage';
import Page from 'components/layout/Page';
import ReactGA from 'react-ga4';
import RejectSubscriptionModal from 'components/subscriptions/RejectSubscriptionModal';
import SearchInput from 'components/forms/SearchInput';
import SubscriptionImportGrid from './SubscriptionImportGrid';
import styled from 'styled-components';
import useBusinesses from 'hooks/useBusinesses';
import useLocalState from 'hooks/useLocalState';
import useSubscriptionBulk from 'hooks/useSubscriptionBulk';

const UtilityBar = styled.section`
    display: flex;
    gap: ${spaceM};
    margin-bottom: ${spaceM};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: ${spaceL} 0;
    gap: ${spaceS};
`;

const NotificationMessageWrapper = styled.div`
    max-width: ${screenWidthMini};
    margin-bottom: ${spaceM};
`;

function SubscriptionImportPage(): JSX.Element {
    const { t } = useTranslation();
    const { open, setModalWidth, lockModal, setCloseButtonVisible } = useModal();
    const { businesses, isLoading: isLoadingBusinesses, error: errorBusinesses } = useBusinesses();

    const { addToast } = useToast();
    const {
        actions: {
            removeAllBulkImportSubscriptionOrder,
            removeBulkImportSubscriptionOrder,
            updateBulkImportSubscriptionOrder,
        },
        state: { bulkImportSubscriptionOrder },
    } = useLocalState();

    const [searchParams] = useSearchParams();
    const searchText = searchParams.get('searchTerm') ?? '';

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
    } = useForm<ConsentFormType>({
        mode: 'onBlur',
        resolver: yupResolver(ConsentFormSchema),
        shouldUnregister: false,
    });

    const { createSubscriptions, isLoading: isLoadingCreateSubscriptions } = useSubscriptionBulk();

    const subscriptions = useMemo(() => {
        const validatedSubscriptions = validateContactsEmail(bulkImportSubscriptionOrder);
        const text = searchText.toLocaleLowerCase();
        return validatedSubscriptions.filter(
            (subscr) =>
                subscr.reference.toLowerCase().includes(text) ||
                subscr.licencePlate.toLowerCase().includes(text) ||
                subscr.contact?.firstName?.toLowerCase().includes(text) ||
                subscr.contact?.lastName?.toLowerCase().includes(text),
        );
    }, [bulkImportSubscriptionOrder, searchText]);

    const openBrowseModal = useCallback((): void => {
        setModalWidth(modalWidthMedium);
        open(<ImportFileModal reset={reset} />);
    }, [setModalWidth, open, reset]);

    const deleteAllOrders = useCallback((): void => {
        removeAllBulkImportSubscriptionOrder();
        addToast({ message: t('subscription.importSubscriptions.deleteAll'), type: EToastType.SUCCESS });
    }, [removeAllBulkImportSubscriptionOrder, addToast, t]);

    const deleteAllOrdersModal = useCallback((): void => {
        setModalWidth(modalWidthMedium);
        setCloseButtonVisible(false);
        lockModal(true);
        open(
            <RejectSubscriptionModal header={t('rejectSubscriptionModal.header.all')} deleteOrder={deleteAllOrders} />,
        );
    }, [setModalWidth, open, t, deleteAllOrders, lockModal, setCloseButtonVisible]);

    const areRowsInvalid = useMemo(() => {
        return subscriptions.some((subscription: IBulkSubscriptionType) => subscription.error);
    }, [subscriptions]);

    const { countryCode } = useCountrySpecificContent();

    const bulkOrderSubmit = useCallback(async () => {
        try {
            const sanitizedOrders = sanitizeBulkSubscriptionOrders(bulkImportSubscriptionOrder, countryCode);
            ReactGA.event(ETrackingEvent.IMPORT_SUBSCRIPTIONS, {
                action: ETrackingAction.SUBMIT,
                count: sanitizedOrders.length,
            });

            await createSubscriptions
                .mutateAsync({ subscriptionOrders: sanitizedOrders })
                .then(({ subscriptionOrderResponses }) => {
                    let anyFailed = false;

                    subscriptionOrderResponses.forEach((order) => {
                        if (order.created) {
                            removeBulkImportSubscriptionOrder(order.row);
                        } else {
                            anyFailed = true;
                            const subscriptionIndex = subscriptions.findIndex((sub) => sub.row === order.row);
                            if (subscriptionIndex !== -1) {
                                const subscription = { ...subscriptions[subscriptionIndex] };
                                subscription.error = true;
                                subscription.errorType = order.errorType;
                                updateBulkImportSubscriptionOrder(subscription);
                            }
                        }
                    });

                    if (anyFailed) {
                        addToast({
                            message: t('subscription.importSubscriptions.errorMessage'),
                            type: EToastType.ERROR,
                        });
                    } else {
                        addToast({
                            message: t('subscription.importSubscriptions.successMessage'),
                            type: EToastType.SUCCESS,
                        });
                        reset();
                    }
                });
        } catch (e) {
            if (e instanceof ApiError) {
                const message = e.temporary
                    ? t('general.errorToast')
                    : t('subscription.create.createSubscriptionError');
                addToast({ message, type: EToastType.ERROR });
            }
        }
    }, [
        addToast,
        bulkImportSubscriptionOrder,
        createSubscriptions,
        removeBulkImportSubscriptionOrder,
        reset,
        subscriptions,
        t,
        updateBulkImportSubscriptionOrder,
        countryCode,
    ]);

    const apiErrors = [];
    if (!isLoadingBusinesses && errorBusinesses) {
        apiErrors.push(errorBusinesses);
    }

    return (
        <Page
            dataTestId="subscriptionImportPage"
            title={t('subscription.importSubscriptions.title')}
            errors={apiErrors}
        >
            <UtilityBar>
                <SearchInput placeholder={t('subscription.importSubscriptions.searchPlaceHolder')} />
                <Button
                    dataTestId="subscriptionImportFileButton"
                    variant={ButtonVariant.PRIMARY}
                    onClick={openBrowseModal}
                >
                    {t('subscription.importFile')}
                </Button>
            </UtilityBar>

            {areRowsInvalid ? (
                <NotificationMessageWrapper>
                    <NotificationMessage
                        type={EInfoType.ERROR}
                        message={t('subscription.importSubscriptions.invalidInfoMessage')}
                    />
                </NotificationMessageWrapper>
            ) : null}

            <form
                noValidate
                onSubmit={handleSubmit(() => {
                    if (!areRowsInvalid) {
                        bulkOrderSubmit();
                    } else {
                        scrollToTop();
                    }
                })}
            >
                <SubscriptionImportGrid
                    lockModal={lockModal}
                    setCloseButtonVisible={setCloseButtonVisible}
                    businesses={businesses}
                    subscriptions={subscriptions}
                    open={open}
                    setModalWidth={setModalWidth}
                    removeBulkImportSubscriptionOrder={removeBulkImportSubscriptionOrder}
                    addToast={addToast}
                    isLoading={isLoadingBusinesses}
                />
                {subscriptions.length > 0 ? (
                    <>
                        <ConsentForm
                            termsAndConditionsRegister={register('termsAndConditionsConsent')}
                            termsAndConditionsError={errors.termsAndConditionsConsent}
                            termsAndConditionsChecked={watch('termsAndConditionsConsent')}
                        />
                        <ButtonContainer>
                            <Button
                                dataTestId="subscriptionImportPageDeleteAll"
                                variant={ButtonVariant.SECONDARY}
                                onClick={deleteAllOrdersModal}
                                disabled={isLoadingCreateSubscriptions}
                            >
                                {t('form.button.deleteAll')}
                            </Button>
                            <Button
                                dataTestId="subscriptionImportPageSendOrder"
                                type={ButtonType.SUBMIT}
                                variant={ButtonVariant.PRIMARY}
                                isLoading={isLoadingCreateSubscriptions}
                            >
                                {t('form.button.sendOrder')}
                            </Button>
                        </ButtonContainer>
                    </>
                ) : null}
            </form>
        </Page>
    );
}
export default SubscriptionImportPage;
