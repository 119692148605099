import {
    borderRadiusM,
    font,
    linkHoverBackgound,
    mediumBlue,
    primaryMerBlue,
    spaceS,
    transitionSnappy,
    white,
} from 'styles/variables';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html.is-loading,
  html.is-error,
  html.is-loading body,
  html.is-error body,
  html.is-loading #root,
  html.is-error #root {
      height: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
  }

  html.is-error section  {
      align-items: center;
      border: none;
      display: flex;
      height: 100%;
      max-width: none;
  }
  
  html {
    font-size: 16px;
    // Fix for iPhone X
    padding: env(safe-area-inset);
  }

  body {
    background-color: ${white};
    color: ${font.color.default};
    font-size:${font.size.m};
    font-family: ${font.body};
    font-weight: ${font.weight.regular};
    -webkit-font-smoothing: antialiased;

    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  .ReactModal__Overlay--after-open {
    z-index: 9999;
    overflow-y: auto;
  }

  //Reset default styles
  h1, h2, h3, h4, h5, h6 {
    font-family: ${font.body};
    font-weight: ${font.weight.semiBold};
    font-size: ${font.size.m};
    margin: 0;
  }

  ul, ol {
    list-style:none;
  }

  p {
    margin-bottom: ${spaceS};
    line-height: ${font.lineHeight.l};
  }

  a {
    color: ${primaryMerBlue};
    transition: all ${transitionSnappy};

    &:hover {
      background-color: ${linkHoverBackgound};
    }

    &:focus {
      outline: 4px solid ${mediumBlue};
    }
  }

  button{
    cursor:pointer;
    &:disabled{
      cursor:not-allowed;
    }
  }

  strong {
    font-weight: normal;
    font-family: 'SharpSans Semibold', sans-serif;
  }

  .tippy-box[data-theme='light'] {
    border-radius: ${borderRadiusM};
    font-size: ${font.size.m};
    .tippy-content{
      padding: 0px;
    }
  }
`;

export default GlobalStyle;
