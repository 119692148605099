import { GENERAL_BFF_PREFIX } from 'constants/general';
import { Subscription, SubscriptionOrderSubmitType, SubscriptionOrderSummary } from 'types/subscription';
import { UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

export type useSubscriptionResponseType = {
    subscription?: Subscription;
    error?: ApiError;
    isLoading: boolean;
    isInitialLoading?: boolean;
    patchSubscription: UseMutationResult<Subscription, ApiError, Partial<Subscription>>;
    createSubscriptions: UseMutationResult<SubscriptionOrderSummary, ApiError, SubscriptionOrderSubmitType>;
};

type useSubscriptionPropType = {
    businessId?: string;
    subscriptionId?: string;
};

const useSubscription = ({ businessId, subscriptionId }: useSubscriptionPropType): useSubscriptionResponseType => {
    const { get, patch } = useApi<Subscription, SubscriptionOrderSubmitType, Partial<Subscription>>();
    const { post } = useApi<SubscriptionOrderSummary, SubscriptionOrderSubmitType>();
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${businessId}/subscriptions/${subscriptionId}`;

    const fetcher: () => Promise<Subscription> = () => get(url);
    const queryClient = useQueryClient();
    const {
        data: subscription,
        error,
        isFetching: isLoading,
        isLoading: isInitialLoading,
    } = useQuery<Subscription, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        enabled: !!businessId && !!subscriptionId,
    });

    const patchSubscription = useMutation<Subscription, ApiError, Partial<Subscription>>({
        mutationFn: (patchSubscriptionData: Partial<Subscription>) => patch(url, patchSubscriptionData),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [url] }),
    });

    const createSubscriptions = useMutation<SubscriptionOrderSummary, ApiError, SubscriptionOrderSubmitType>({
        mutationFn: (subscriptionsOrder: SubscriptionOrderSubmitType) =>
            post(`${GENERAL_BFF_PREFIX}/v1/businesses/${businessId}/subscriptions`, subscriptionsOrder),
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [`${GENERAL_BFF_PREFIX}/v1/businesses/${businessId}/subscriptions`],
            }),
    });

    return {
        subscription,
        error: error ?? undefined,
        isLoading: isLoading || createSubscriptions.isPending,
        patchSubscription,
        createSubscriptions,
        isInitialLoading,
    };
};

export default useSubscription;
