import { font, spaceL, spaceXs } from 'styles/variables';
import PencilIcon from 'assets/icons/pencil.svg?react';
import React from 'react';
import styled from 'styled-components';

export const DetailsList = styled.ul``;

const titleWidth = '30%';
const itemStyling = `
    display: grid;  
    gap: ${spaceXs} ${spaceL};
    line-height: ${font.lineHeight.l};
    > :first-child {
        font-weight: ${font.weight.semiBold};
    }
    >:not(:first-child) {
         word-break: break-word;
}
        
`;

const Item = styled.li`
    ${itemStyling}
    grid-template-columns: ${titleWidth} 1fr;
    margin-bottom: ${spaceXs};
`;

const ClickableItem = styled.li`
    button {
        background-color: transparent;
        border: none;
        text-align: left;
        font-size: inherit;
        font-family: inherit;
        color: inherit;
        ${itemStyling}
        grid-template-columns: ${titleWidth} 1fr auto;
        width: 100%;

        &:disabled {
            color: ${font.color.default};
            cursor: not-allowed;
        }
    }
    margin-bottom: ${spaceXs};
`;

const StyledPencilIcon = styled(PencilIcon)`
    height: 1.5rem;

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

type ListItemProps = {
    title: string;
    value: string | React.ReactNode;
};
export function ListItem({ title, value }: ListItemProps): JSX.Element {
    return (
        <Item>
            <span>{title}</span>
            <span>{value}</span>
        </Item>
    );
}

type ClickableListItemProps = {
    dataTestId: string;
    title: string;
    text: string;
    onClick: () => void;
    disabled?: boolean;
};

export function ClickableListItem({ dataTestId, title, text, onClick, disabled }: ClickableListItemProps): JSX.Element {
    return (
        <ClickableItem>
            <button type="button" data-testid={dataTestId} onClick={onClick} disabled={disabled}>
                <span>{title}</span>
                <span>{text}</span>
                <StyledPencilIcon className={disabled ? 'disabled' : ''} />
            </button>
        </ClickableItem>
    );
}
