import { EUsageType } from 'types/subscription';
import { ReactElement } from 'react';
import {
    borderRadiusM,
    colorHome,
    colorInternal,
    colorPublic,
    font,
    primaryMerBlue,
    spaceL,
    spaceM,
    spaceXs,
    spaceXxl,
    white,
} from 'styles/variables';
import { formatNumber } from 'utils/number';
import { useTranslation } from 'react-i18next';
import HomeIcon from 'assets/icons/homeLargeIcon.svg?react';
import InternalIcon from 'assets/icons/internal.svg?react';
import PublicIcon from 'assets/icons/public.svg?react';
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
    border-radius: ${borderRadiusM};
    &.public {
        color: ${white};
        background-color: ${colorPublic};
    }

    &.internal {
        color: ${white};
        background-color: ${colorInternal};
    }

    &.home {
        color: ${primaryMerBlue};
        background-color: ${colorHome};
    }
`;

const IconContainer = styled.div`
    margin: ${spaceL} 0 ${spaceXxl};
`;

const KWhParagrapgh = styled.p`
    display: flex;
    justify-content: center;
    gap: ${spaceXs};
`;
const KWhNumber = styled.span`
    font-size: ${font.size.xxl};
    font-weight: ${font.weight.semiBold};
    line-height: ${font.lineHeight.l};
`;

const KWh = styled.span`
    font-size: ${font.size.xs};
    font-weight: ${font.weight.semiBold};
    line-height: ${font.lineHeight.l};
`;

const Type = styled.p`
    font-size: ${font.size.xs};
    font-weight: ${font.weight.semiBold};
    margin-bottom: ${spaceM};
`;

export type GetKWhUsageProps = {
    kWh: number;
    name: EUsageType;
    dataTestId?: string;
};

type typeOptions = {
    [key: string]: ReactElement<SVGSVGElement>;
};

const icon: typeOptions = {
    PUBLIC: <PublicIcon />,
    INTERNAL: <InternalIcon />,
    HOME: <HomeIcon />,
};

function KWhUsageByType({ kWh, name, dataTestId }: GetKWhUsageProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Container className={name.toLowerCase()} data-testid={dataTestId}>
            <IconContainer data-testid="kwhUsageTypeIcon">{icon[name]}</IconContainer>
            <KWhParagrapgh>
                <KWhNumber>{formatNumber(kWh)}</KWhNumber>
                <KWh>{t('subscription.statistics.kpi.kWh')}</KWh>
            </KWhParagrapgh>
            <Type>{t(`usageType.${name}`)}</Type>
        </Container>
    );
}

export default KWhUsageByType;
