import { Contact } from 'types';
import { EMAIL_REGEXP, GENERAL_BFF_PREFIX } from 'constants/general';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from './useApi';

type useContactReturnType = {
    contact?: Contact;
    isLoading: boolean;
    error?: ApiError;
};

const useContact = (businessId: string, email?: string): useContactReturnType => {
    const isEmailCorrect = EMAIL_REGEXP.test(email ?? '');

    const encodedEmail = encodeURIComponent(email ?? '');
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${businessId}/contacts?email=${encodedEmail}`;
    const { get } = useApi<Contact>();

    const fetcher: () => Promise<Contact> = () => get(url);

    const {
        data: contact,
        isFetching: isLoading,
        error,
    } = useQuery<Contact, ApiError>({ queryKey: [url], queryFn: fetcher, enabled: isEmailCorrect && !!businessId });

    return {
        contact,
        isLoading,
        error: error ?? undefined,
    };
};

export default useContact;
