import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Form from './Form';
import SearchIcon from 'assets/icons/search.svg?react';
import TextInput from './TextInput';
import styled from 'styled-components';

const StyledTextInput = styled(TextInput)`
    margin: 0;
    max-width: 30rem;
    min-width: 12.5rem;
    input {
        margin: 0;
    }
`;

type Props = {
    placeholder: string;
};

const StyledForm = styled(Form)`
    width: 30rem;
`;

export default function SearchInput({ placeholder }: Props): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm') ?? '');
    const searchDebounce = useDebounce(searchTerm, 500);

    useEffect(() => {
        setSearchTerm(searchParams.get('searchTerm') ?? '');
    }, [searchParams]);

    const formMethods = useForm();

    useEffect(() => {
        // logic to start after first 3 characters
        if (
            searchDebounce.length > 2 &&
            searchTerm &&
            searchTerm.length > 2 &&
            searchParams.get('searchTerm') !== searchTerm
        ) {
            const querySearchParam = searchParams;
            querySearchParam.set('searchTerm', searchDebounce);

            setSearchParams(querySearchParam);
        } else if (searchTerm.length === 0 && searchParams.get('searchTerm') !== searchTerm) {
            setSearchTerm('');

            const querySearchParam = searchParams;

            querySearchParam.delete('searchTerm');
            setSearchParams(querySearchParam);
        }
    }, [searchDebounce, searchParams, setSearchParams, searchTerm]);

    return (
        <StyledForm formMethods={formMethods}>
            <StyledTextInput
                placeholder={placeholder}
                value={searchTerm}
                {...formMethods.register('search')}
                dataTestId="searchField"
                onChange={(e) => {
                    formMethods.setValue('search', e.target.value);
                    setSearchTerm(e.target.value);
                }}
                onClearFieldClick={() => {
                    setSearchTerm('');
                    const querySearchParam = searchParams;
                    querySearchParam.delete('searchTerm');
                    setSearchParams(querySearchParam);
                }}
                StartIcon={SearchIcon}
            />
        </StyledForm>
    );
}
