import { ESortingOrder } from 'types';
import { ESubscriptionSortingColumns, SubscriptionOrdersResult } from 'types/subscription';
import { GENERAL_BFF_PREFIX, PAGE_SIZE } from 'constants/general';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

export type useSubscriptionOrersResponse = {
    subscriptionOrderList?: SubscriptionOrdersResult;
    isLoading: boolean;
    error?: ApiError;
    mutateSubscriptionOrders: () => void;
};

type GetSubscriptionOrdersParams = {
    businessId: string;
    pageNumber?: number;
    pageSize?: number;
    sortColumn?: ESubscriptionSortingColumns;
    sortDirection?: ESortingOrder;
};

const useSubscriptionOrders = (prop: GetSubscriptionOrdersParams): useSubscriptionOrersResponse => {
    const { businessId, pageNumber = 0, pageSize = PAGE_SIZE, sortColumn, sortDirection } = prop;

    const paramsArray: string[][] = [];
    paramsArray.push(['pageSize', pageSize.toString()]);
    paramsArray.push(['pageNumber', pageNumber.toString()]);

    if (sortColumn && sortDirection) {
        paramsArray.push(['sortColumn', sortColumn.toString()], ['sortDirection', sortDirection.toString()]);
    }

    const { get } = useApi<SubscriptionOrdersResult>();
    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${businessId}/subscription-orders?${urlParams}`;

    const fetcher: () => Promise<SubscriptionOrdersResult> = () => get(url);
    const {
        data: subscriptionOrderList,
        isFetching: isLoading,
        error,
    } = useQuery<SubscriptionOrdersResult, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        enabled: !!businessId,
    });

    const queryClient = useQueryClient();
    const mutateSubscriptionOrders = (): void => {
        queryClient.invalidateQueries({ queryKey: [url] });
    };

    return {
        subscriptionOrderList,
        mutateSubscriptionOrders,
        isLoading,
        error: error ?? undefined,
    };
};

export default useSubscriptionOrders;
