import { InvoiceResponse } from 'types/billing';
import { PAGE_SIZE, REACT_QUERY_ONE_CALL_OPTIONS } from 'constants/general';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from './useApi';

type useInvoicesResult = {
    invoicesResponse?: InvoiceResponse;
    isLoading: boolean;
    error?: ApiError;
};

type useInvoicesProps = {
    businessId: string;
    pageNumber?: number;
    pageSize?: number;
};

const useInvoices = (prop: useInvoicesProps): useInvoicesResult => {
    const { businessId, pageNumber = 0, pageSize = PAGE_SIZE } = prop;

    const paramsArray: string[][] = [];
    paramsArray.push(['pageSize', pageSize.toString()]);
    paramsArray.push(['pageNumber', pageNumber.toString()]);
    const urlParams = new URLSearchParams(paramsArray).toString();

    const { get } = useApi<InvoiceResponse>();

    const url = `/v1/businesses/${businessId}/invoices?${urlParams}`;
    const fetcher: () => Promise<InvoiceResponse> = () => get(url);

    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery<InvoiceResponse, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        ...{ enabled: !!businessId, ...REACT_QUERY_ONE_CALL_OPTIONS },
    });

    return {
        invoicesResponse: data,
        isLoading,
        error: error ?? undefined,
    };
};

export default useInvoices;
