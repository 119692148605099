import { FirebaseApp, initializeApp } from 'firebase/app';
import { RemoteConfig, fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
    apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
};

let app: FirebaseApp;

export const remoteConfig = (): RemoteConfig => {
    if (!app) {
        app = initializeApp(firebaseConfig);
    }
    return getRemoteConfig(app);
};

export const initRemoteConfig = async (): Promise<void> => {
    const config = remoteConfig();
    if (config) {
        config.settings.minimumFetchIntervalMillis = import.meta.env.VITE_APP_ENV === 'live' ? 12 * 60 * 60 * 1000 : 0;

        config.defaultConfig = {
            SHOW_FACILITY_MANAGEMENT: false,
            SHOW_ONBOARD_GUIDE: false,
            SHOW_INVOICES: false,
            USE_SIGNALR: false,
        };

        await fetchAndActivate(config);
    }
};
