import { Divider } from './Divider';

type EditDetailsSectionProps = {
    children: React.ReactNode;
};

export function EditDetailsSection({ children }: EditDetailsSectionProps): JSX.Element {
    return (
        <section>
            <Divider />
            {children}
        </section>
    );
}
