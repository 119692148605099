import { DynamicKpiData } from 'types/subscription';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

type UseKpiProps = {
    businessIds: string[];
    year: string;
    month?: string;
    week?: string;
};
type UseKpiResult = {
    kpiData?: DynamicKpiData;
    isLoading: boolean;
    error?: ApiError;
};
type UseKpiBodyProps = {
    businessIds: string[];
};
const useKpi = ({ businessIds, year, month, week }: UseKpiProps): UseKpiResult => {
    const paramsArray: string[][] = [];
    paramsArray.push(['year', year]);

    if (month) {
        paramsArray.push(['month', month]);
    } else if (week) {
        paramsArray.push(['week', week]);
    }

    const { post } = useApi<DynamicKpiData, UseKpiBodyProps>();
    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `${GENERAL_BFF_PREFIX}/v1/billing-transactions/key-performance-indicators?${urlParams}`;
    const fetcher: () => Promise<DynamicKpiData> = () => post(url, { businessIds });

    const {
        data: kpiData,
        isFetching: isLoading,
        error,
    } = useQuery<DynamicKpiData, ApiError>({
        queryKey: [`${url}?${businessIds}`],
        queryFn: fetcher,
        enabled: !!year && !!businessIds,
    });

    return {
        kpiData,
        isLoading,
        error: error ?? undefined,
    };
};

export default useKpi;
