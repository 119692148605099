import { GENERAL_BFF_PREFIX } from 'constants/general';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from './useApi';

export type EmbedTokenType = {
    expiresAt: string;
    token: string;
};

export type useFacilityStatisticsResponse = {
    reportId?: string;
    embedUrl?: string;
    embedToken?: EmbedTokenType;
    removeFacilityStatisticsQueryKey: () => void;
    error?: ApiError;
    isLoading: boolean;
};

export type useFacilityStatisticsType = {
    businessIds: string[];
    enabled?: boolean;
};

type useFacilityStatisticsPostType = {
    businessIds: string[];
};

const useFacilityStatistics = (prop: useFacilityStatisticsType): useFacilityStatisticsResponse => {
    const { businessIds, enabled = true } = prop;

    const url = `${GENERAL_BFF_PREFIX}/v1/facility-statistics:generate-embed-token`;
    const { post } = useApi<useFacilityStatisticsResponse, useFacilityStatisticsPostType>();

    const fetcher = (): Promise<useFacilityStatisticsResponse> => post(url, { businessIds });

    const { data, error, isLoading } = useQuery<useFacilityStatisticsResponse, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        enabled,
        refetchInterval: 3600000, // one hour
    });

    const queryClient = useQueryClient();
    const removeFacilityStatistics: () => void = () => {
        queryClient.removeQueries({ queryKey: [url] });
    };

    return {
        reportId: data?.reportId,
        embedUrl: data?.embedUrl,
        embedToken: data?.embedToken,
        removeFacilityStatisticsQueryKey: removeFacilityStatistics,
        error: error ?? undefined,
        isLoading,
    };
};

export default useFacilityStatistics;
