export enum ETrackingEvent {
    MENU_ITEM = 'merhub_menuItem',
    NAVIGATION_MENU = 'merhub_navigationMenu',
    IMPORT_SUBSCRIPTIONS = 'merhub_importSubscription',
    CREATE_SUBSCRIPTIONS = 'merhub_createSubscription',
    EXPORT_FILE = 'merhub_exportFile',
    SUBSCRIPTION_PLANS_INFO = 'merhub_subscriptionPlanInfo',
    CHANGE_EMAIL = 'merhub_changeEmail',
    INFO_POPUP = 'merhub_infoPopup',
    FAQ = 'merhub_faq',
    EDIT = 'merhub_edit',
    APPLY_FILTER = 'merhub_applyFilter',
    CHANGE_LANGUAGE = 'merhub_changeLanguage',
    CHANGE_ORGANISATION = 'merhub_changeOrganisation',
    LOGOUT = 'merhub_logout',
    FILTER_ORGANISATION = 'merhub_filterOrganisation',
}

export enum ETrackingOrigin {
    STARTPAGE = 'startpage',
    SUBSCRIPTION_IMPORT = 'subscriptionImport',
    SUBSCRIPTION = 'subscription',
    SUBSCRIPTION_STATISTICS = 'subscriptionStatistics',
    SUBSCRIPTION_DETAILS = 'subscriptionDetails',
    SUBSCRIPTION_ORDER = 'subscriptionOrder',
    SUBSCRIPTION_ORDER_DETAILS = 'subscriptionOrderDetails',
    CHARGING_HISTORY = 'chargingHistory',
    MY_ACCOUNT = 'myAccount',
    CREATE_SUBSCRIPTION = 'createSubscription',
    FACILITY_MANAGEMENT = 'facilityManagement',
    INVOICES = 'invoices',
    FACILITY_STATISTICS = 'facilityStatistics',
}
export enum ETrackingAction {
    COLLAPSE = 'collapse',
    EXPAND = 'expand',
    OPEN_MODAL = 'openModal',
    DOWNLOAD_FILE = 'downloadFile',
    LINK_CLICK = 'externalLinkClick',
    SUBMIT = 'submit',
    CANCEL = 'cancel',
    EDIT_MODE = 'editMode',
    SHOW_ALL = 'showAll',
    SHOW_ONE = 'showOne',
    IMPORT = 'import',
}
export enum ETrackingType {
    LEGEND_INFO = 'legendInfo',
    EXPORT_INFO = 'exportInfo',
    STATUS_INFO = 'statusInfo',
    RESET_INFO = 'resetInfo',
    NAVIGATION_TOGGLE_BUTTON = 'navigationToggleButton',
    DATEPICKER_FILTER = 'datePickerFilter',
    INVOICE = 'invoice',
    PERSONALISED = 'personalised',
    UNPERSONALISED = 'unpersonalised',
    READ_MORE = 'readMore',
}
// used for info popups
export interface IPopupTracking {
    type: ETrackingType;
    origin: ETrackingOrigin;
}
