import { EConnectorStatus, EConnectorStatusNotification, IProperty } from 'types/facility';

export const mapStatuses: (status: EConnectorStatusNotification) => EConnectorStatus = (
    status: EConnectorStatusNotification,
) => {
    switch (status) {
        case EConnectorStatusNotification.AVAILABLE:
            return EConnectorStatus.AVAILABLE;
        case EConnectorStatusNotification.UNAVAILABLE:
            return EConnectorStatus.UNAVAILABLE;
        case EConnectorStatusNotification.RESERVED:
            return EConnectorStatus.UNAVAILABLE;
        case EConnectorStatusNotification.FAULTED:
            return EConnectorStatus.FAULTED;
        case EConnectorStatusNotification.PREPARING:
            return EConnectorStatus.OCCUPIED;
        case EConnectorStatusNotification.CHARGING:
            return EConnectorStatus.OCCUPIED;
        case EConnectorStatusNotification.FINISHING:
            return EConnectorStatus.OCCUPIED;
        case EConnectorStatusNotification.OCCUPIED:
            return EConnectorStatus.OCCUPIED;
        case EConnectorStatusNotification.PAUSED:
            return EConnectorStatus.OCCUPIED;
        case EConnectorStatusNotification.DISCHARGING:
            return EConnectorStatus.OCCUPIED;
        case EConnectorStatusNotification.NEW:
            return EConnectorStatus.UNKNOWN;
        case EConnectorStatusNotification.UNKNOWN:
            return EConnectorStatus.UNKNOWN;
        case EConnectorStatusNotification.IN_PROVISION:
            return EConnectorStatus.UNKNOWN;
        default:
            return status;
    }
};

export const getListWithFilteredChargersByBusinessId = (
    propertyList: IProperty[],
    selectedOrganisation: string,
): IProperty[] => {
    return propertyList.map((property) => {
        // Filter sites with chargers matching the selected organization
        const filteredSites = property.sites
            .map((site) => {
                const filteredChargers = site.chargers.filter((charger) => charger.businessId === selectedOrganisation);
                return { ...site, chargers: filteredChargers };
            })
            .filter((site) => site.chargers.length > 0); // Keep only sites with matching chargers

        return { ...property, sites: filteredSites };
    });
};
