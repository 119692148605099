import { FieldError } from 'react-hook-form';
import { ForwardedRef, forwardRef, memo } from 'react';
import {
    backgroundColorDisabled,
    borderColorDisabled,
    borderColorGrey,
    borderRadiusS,
    darkBlue,
    darkerBlue,
    mediumGrey,
    primaryMerBlue,
    shadowBlueSharp,
    spaceXs,
    transitionSnappy,
} from 'styles/variables';
import Check from 'assets/icons/checkmark_solid.svg?react';
import FieldErrorMessage from 'components/forms/FieldError';
import styled from 'styled-components';
import uuid from 'utils/uuid';

const Label = styled.label`
    position: relative;
    cursor: pointer;
    display: flex;
    gap: ${spaceXs};
    max-width: 100%;
    padding: ${spaceXs} 0;

    input {
        opacity: 0;
        width: 0;
        height: 0;
        -moz-appearance: initial;
        -webkit-appearance: initial;
    }

    .checkmark {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        border: 2px solid ${borderColorGrey};
        border-radius: ${borderRadiusS};
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ${transitionSnappy};
        color: white;

        &.checked {
            background: ${primaryMerBlue};
            border-color: ${primaryMerBlue};
        }
    }
    &.disabled {
        cursor: not-allowed;
        .checkmark {
            border-color: ${borderColorDisabled};
            background: ${backgroundColorDisabled};
            color: ${mediumGrey};
        }
    }

    &:hover:not(.disabled) {
        .checkmark {
            border-color: ${darkBlue};
            &.checked {
                background: ${darkBlue};
            }
        }
    }

    &:focus-within {
        .checkmark {
            box-shadow: ${shadowBlueSharp};
        }
    }

    &:active:not(.disabled) {
        .checkmark {
            box-shadow: none;
            border-color: ${darkerBlue};
            &.checked {
                background: ${darkerBlue};
            }
        }
    }
`;

const StyledFieldErrorMessage = styled(FieldErrorMessage)`
    margin-top: 0;
    margin-bottom: 12px;
`;

type Props = {
    value?: string;
    id?: string;
    dataTestId?: string;
    required?: boolean;
    name?: string;
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string | React.ReactNode;
    disabled?: boolean;
    fieldError?: FieldError | null;
    className?: string;
};

const Checkbox = forwardRef(
    (
        {
            id = uuid(),
            dataTestId,
            required,
            name,
            checked,
            onChange,
            label,
            value,
            disabled,
            fieldError,
            className,
        }: Props,
        ref: ForwardedRef<HTMLInputElement>,
    ): JSX.Element => {
        return (
            <div className={className}>
                <Label htmlFor={id} className={`${disabled ? 'disabled' : ''}`}>
                    <span className={`checkmark ${checked ? 'checked' : ''}`}>{checked ? <Check /> : null}</span>
                    <input
                        value={value}
                        id={id}
                        data-testid={dataTestId}
                        ref={ref}
                        required={required}
                        checked={checked}
                        name={name}
                        onChange={onChange}
                        type="checkbox"
                        disabled={disabled}
                    />
                    <span>{label}</span>
                </Label>
                {fieldError ? <StyledFieldErrorMessage dataTestId={dataTestId} fieldError={fieldError} /> : null}
            </div>
        );
    },
);

export default memo(Checkbox);
