import { AxiosResponse } from 'axios';
import { BlobFile, EExportFileType } from 'types';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { endOfDay } from 'date-fns';
import { getLanguageCode } from 'utils/getLanguageCode';
import useApi from './useApi';

type PostChargingHistoryExportParams = {
    searchTerm?: string;
    dateFrom?: string;
    dateTo?: string;
    businessIds?: string[];
    fileType?: EExportFileType;
};

type PostChargingHistoryPayload = {
    businessIds?: string[];
};

const getDownloadUrl = (params: PostChargingHistoryExportParams): string => {
    const { dateTo, dateFrom, searchTerm, fileType } = params;
    const paramsArray: string[][] = [];
    const language = getLanguageCode();
    const selectedFileType = fileType ?? EExportFileType.EXCEL;

    paramsArray.push(['fileType', selectedFileType]);

    if (language) {
        paramsArray.push(['language', language]);
    }
    if (searchTerm) {
        paramsArray.push(['searchTerm', searchTerm]);
    }
    if (dateFrom && dateTo) {
        paramsArray.push(['dateFrom', new Date(dateFrom).toISOString()]);
        paramsArray.push(['dateTo', endOfDay(new Date(dateTo)).toISOString()]);
    }

    const urlParams = new URLSearchParams(paramsArray).toString();
    return `${GENERAL_BFF_PREFIX}/v1/billing-transactions/exports?${urlParams}`;
};

const useSubscriptionChargeDetailsExport = (
    prop: PostChargingHistoryExportParams,
): UseMutationResult<BlobFile, unknown, void, unknown> => {
    const { post } = useApi<AxiosResponse, PostChargingHistoryPayload>();

    const getExport = useMutation<BlobFile>({
        mutationFn: async () => {
            const response = await post(getDownloadUrl(prop), { businessIds: prop.businessIds }, { download: true });

            const fileName = response.headers['content-disposition']?.replaceAll('attachment; filename=', '');
            return { file: response.data, fileName: fileName || 'export' };
        },
    });

    return getExport;
};

export default useSubscriptionChargeDetailsExport;
