import { ECurrency } from 'types';

export enum EPaymentStatus {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
}

export interface InvoiceResponse {
    pageNumber: number;
    pageSize: number;
    numberOfElements: number;
    totalNumberOfPages: number;
    totalNumberOfElements: number;
    isLastPage: boolean;
    invoices: Invoice[];
}

export type Invoice = {
    paymentStatus: EPaymentStatus;
    invoiceNumber: string;
    creationDate: string;
    amountExcludingVat: number;
    currency: ECurrency;
};

export interface InvoicePdfLinkResponse {
    link: string;
}

export interface InvoicePdfFileResponse {
    data: Blob;
    status: number;
    statusText: string;
}
