export interface IProperty {
    name: string;
    chargerOwnerBusinessIds: string[];
    sites: ISite[];
}

export interface ISite {
    name: string;
    id: string;
    chargers: ICharger[];
}

export interface ICharger {
    businessId: string;
    name: string;
    identityKey: string;
    model: string;
    equipmentId: string;
    serialNumber: string;
    connectors: IConnector[];
}

export interface IConnector {
    connectorId: string;
    evseId: string;
    type: string;
}

export enum EConnectorStatus {
    AVAILABLE = 'AVAILABLE',
    OCCUPIED = 'OCCUPIED',
    FAULTED = 'FAULTED',
    UNAVAILABLE = 'UNAVAILABLE',
    UNKNOWN = 'UNKNOWN',
}

export enum EConnectorStatusNotification {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE',
    RESERVED = 'RESERVED',
    FAULTED = 'FAULTED',
    OCCUPIED = 'OCCUPIED',
    PREPARING = 'PREPARING',
    CHARGING = 'CHARGING',
    FINISHING = 'FINISHING',
    PAUSED = 'PAUSED',
    DISCHARGING = 'DISCHARGING',
    UNKNOWN = 'UNKNOWN',
    NEW = 'NEW',
    IN_PROVISION = 'IN_PROVISION',
}

export interface IConnectorStatusNotification {
    EvseId: string;
    Data: string;
}

export interface IConnectorStatusNotificationData {
    ConnectorId: number;
    ErrorInfo: {
        ErrorCode: string;
        ErrorMessage: string;
        VendorErrorCode: string;
    };
    EvseConnectorIdentityKey: string;
    EvseIdentityKey: string;
    SocketStatus: EConnectorStatusNotification;
}

export interface IConnectorStatus {
    evseId: string;
    status: EConnectorStatus;
}
