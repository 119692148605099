import { EToastType } from 'types';
import { Invoice, InvoicePdfFileResponse, InvoicePdfLinkResponse } from 'types/billing';
import { useToast } from 'contexts/Toast';
import { useTranslation } from 'react-i18next';
import ApiError from 'classes/ApiError';
import useApi from './useApi';

type useInvoicePdfProps = Pick<Invoice, 'invoiceNumber' | 'creationDate'>;
type useInvoicePdfReturn = ({ invoiceNumber, creationDate }: useInvoicePdfProps) => Promise<void>;

const useInvoicePdf = (): useInvoicePdfReturn => {
    const { get } = useApi<InvoicePdfLinkResponse | InvoicePdfFileResponse>();
    const { addToast } = useToast();
    const { t } = useTranslation();

    const getInvoicePdf = async ({ invoiceNumber, creationDate }: useInvoicePdfProps): Promise<void> => {
        try {
            const url = (await get(
                `/v1/invoices/${invoiceNumber}/link?creationDate=${creationDate}`,
            )) as InvoicePdfLinkResponse;
            window.open(url.link, '_blank');
        } catch (e) {
            if (e instanceof ApiError) {
                const message = e.temporary ? t('general.errorToast') : t('invoices.invoiceTable.errorMessage');
                addToast({ message, type: EToastType.ERROR });
            }
        }
    };
    return getInvoicePdf;
};

export default useInvoicePdf;
