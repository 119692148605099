import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import {
    borderColorGrey,
    borderRadiusM,
    borderRadiusS,
    font,
    lightPink,
    lighterGrey,
    listHoverBackgroundColor,
    listHoverBorderColor,
    spaceS,
    white,
} from 'styles/variables';
import { forwardRef } from 'react';
import EmptyListMessage from 'components/info/EmptyListMessage';
import styled from 'styled-components';

const GridContainer = styled.section`
    border-radius: ${borderRadiusM};
    border: 1px solid ${borderColorGrey};
    overflow: hidden;
`;

const ListStyling = (gridColumns: string): string => `
    li{
        display: grid;
        grid-template-columns: ${gridColumns};
        gap: ${spaceS};
        align-items: center;
        padding: 1rem 1.25rem;
    }
`;
const ClickableListStyling = (gridColumns: string): string => `
    li:first-child,
    li > button {
        display: grid;
        grid-template-columns: ${gridColumns};
        gap: ${spaceS};
        align-items: center;
        padding: 0 1.25rem;
    }
`;

const List = styled.ol<{ $gridColumns: string; $clickable: boolean }>`
    list-style: none;
    overflow: hidden;
    ${({ $clickable, $gridColumns }) =>
        $clickable ? `${ClickableListStyling($gridColumns)}` : `${ListStyling($gridColumns)}`};
`;

export const GridListItem = styled.li`
    border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
    height: 3.5rem;
    background-color: ${white};

    p {
        margin: 0;
    }

    &:not(:first-child) {
        border-top: 1px solid ${borderColorGrey};
    }
    &:nth-child(2) {
        border-top-color: transparent;
    }

    &:hover,
    &.selected {
        background: ${listHoverBackgroundColor};
        border-top-color: ${listHoverBorderColor};
    }

    &:hover + li,
    &.selected + li {
        border-top-color: ${listHoverBorderColor};
    }

    &.invalid-row {
        background-color: ${lightPink};
    }
`;

export const GridListItemButton = styled.button`
    text-align: left;
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;

    font-size: ${font.size.m};
    font-family: ${font.body};
    font-weight: ${font.weight.regular};
`;

export const GridListHeader = styled.li`
    font-weight: ${font.weight.bold};
    height: 3.5rem;
    background: ${lighterGrey};
    border-radius: ${borderRadiusS} ${borderRadiusS} 0 0;
`;

export const Header = styled.span`
    font-weight: ${font.weight.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type GridListProps = {
    children: React.ReactNode;
    gridColumns: string;
    dataTestId?: string;
    className?: string;
    showEmptyListMessage?: boolean;
    isLoading?: boolean;
    clickable?: boolean;
};

const GridList = forwardRef<HTMLDivElement, GridListProps>(
    ({ children, gridColumns, dataTestId, className, showEmptyListMessage, isLoading, clickable = false }, ref) => {
        return (
            <GridContainer ref={ref}>
                <List $gridColumns={gridColumns} $clickable={clickable} data-testid={dataTestId} className={className}>
                    {children}
                </List>
                {isLoading && <LoadingIndicator displayBorder={false} />}
                {showEmptyListMessage && <EmptyListMessage />}
            </GridContainer>
        );
    },
);
export default GridList;
