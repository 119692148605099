import { ETrackingOrigin, ETrackingType } from 'types/tracking';
import { spaceS, spaceXs, spaceXxs } from 'styles/variables';
import { useTranslation } from 'react-i18next';
import AppIcon from 'assets/icons/phone.svg?react';
import CalendarIcon from 'assets/icons/calendar.svg?react';
import CarIcon from 'assets/icons/car_side.svg?react';
import CardIcon from 'assets/icons/credit_card.svg?react';
import ChargeIcon from 'assets/icons/power_circle.svg?react';
import ChargerIcon from 'assets/icons/charger.svg?react';
import CircleIcon from 'assets/icons/pie_circle.svg?react';
import ClockIcon from 'assets/icons/clock.svg?react';
import LocationIcon from 'assets/icons/location_pin.svg?react';
import PopUp from 'components/info/PopUp';
import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import RFIDIcon from 'assets/icons/rfid.svg?react';
import ReferenceIcon from 'assets/icons/speech_bubble.svg?react';
import SubscriptionIcon from 'assets/icons/subscription_type.svg?react';
import TooltipContentComponent from 'components/info/TooltipContent';
import UserIcon from 'assets/icons/user.svg?react';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spaceS};
    li {
        display: grid;
        grid-template-columns: 1rem 1fr;
        gap: ${spaceXs};
        align-items: start;
        margin-bottom: ${spaceXs};
        svg {
            margin-top: ${spaceXxs};
            height: 1rem;
            justify-self: center;
        }
    }
`;

function ChargingHistoryIconInfoPopup(): JSX.Element {
    const { t } = useTranslation();
    return (
        <PopUp
            dataTestId="iconInfoButton"
            gaTracking={{
                type: ETrackingType.LEGEND_INFO,
                origin: ETrackingOrigin.CHARGING_HISTORY,
            }}
            content={
                <TooltipContentComponent
                    headline={t('subscription.iconDescription.title')}
                    content={
                        <Container data-testid="iconInfoList">
                            <ul>
                                <li>
                                    <UserIcon />
                                    <span>{t('subscription.iconDescription.driverName')}</span>
                                </li>
                                <li>
                                    <LocationIcon />
                                    <span>{t('subscription.iconDescription.location')}</span>
                                </li>
                                <li>
                                    <CalendarIcon />
                                    <span>{t('subscription.iconDescription.chargingStartTime')}</span>
                                </li>

                                <li>
                                    <ReferenceIcon />
                                    <span>{t('subscription.iconDescription.reference')}</span>
                                </li>
                                <li>
                                    <ChargeIcon />
                                    <span>{t('subscription.iconDescription.connectorType')}</span>
                                </li>
                                <li>
                                    <ChargerIcon />
                                    <span>{t('subscription.iconDescription.locationType')}</span>
                                </li>
                                <li>
                                    <CarIcon />
                                    <span>{t('subscription.iconDescription.licencePlate')}</span>
                                </li>
                                <li>
                                    <CardIcon />
                                    <span>{t('subscription.iconDescription.chargingCost')}</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <SubscriptionIcon />
                                    <span>{t('subscription.iconDescription.subscription')}</span>
                                </li>
                                <li>
                                    <ClockIcon />
                                    <span>{t('subscription.iconDescription.chargingDuration')}</span>
                                </li>

                                <li>
                                    <RFIDIcon />
                                    <span>{t('subscription.iconDescription.rfid')}</span>
                                </li>
                                <li>
                                    <AppIcon />
                                    <span>{t('subscription.iconDescription.app')}</span>
                                </li>
                                <li>
                                    <CircleIcon />
                                    <span>{t('subscription.iconDescription.chargingConsumption')}</span>
                                </li>
                            </ul>
                        </Container>
                    }
                    icon={<QuestionMarkTooltipIcon />}
                />
            }
        />
    );
}

export default ChargingHistoryIconInfoPopup;
