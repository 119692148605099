import {
    borderRadiusM,
    contentBoxPadding,
    font,
    panelBackgroundColorBlue,
    spaceS,
    spaceXl,
    spaceXs,
} from 'styles/variables';
import styled from 'styled-components';

const Kpi = styled.article`
    background: ${panelBackgroundColorBlue};
    display: flex;
    flex-direction: column;
    gap: ${spaceS};
    padding: ${contentBoxPadding};
    position: relative;
    border-radius: ${borderRadiusM};
`;

const Header = styled.section<{ $infoButton: boolean }>`
    margin-right: ${({ $infoButton }) => ($infoButton ? `${spaceXl}` : 0)};
    display: grid;
    grid-template-columns: 1fr;
`;
const Title = styled.h2`
    font-size: ${font.size.l};
`;

const Subtitle = styled.p`
    margin: 0;
`;

const InfoButton = styled.div`
    position: absolute;
    right: ${spaceXs};
    top: ${spaceXs};
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-evenly;
    font-weight: ${font.weight.semiBold};
    align-items: end;
    gap: ${spaceS};
`;

type KpiContainerProps = {
    title: string | React.ReactElement;
    subtitle?: string;
    tooltip?: React.ReactNode;
    content: React.ReactNode | React.ReactNode[];
    footerItems?: React.ReactNode | React.ReactNode[];
    dataTestId?: string;
};
function KpiContainer({ title, subtitle, content, footerItems, dataTestId, tooltip }: KpiContainerProps): JSX.Element {
    const showInfoButton = !!tooltip;
    return (
        <Kpi data-testid={dataTestId}>
            {showInfoButton && <InfoButton>{tooltip}</InfoButton>}
            <Header $infoButton={showInfoButton}>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Header>
            {content}
            {footerItems && <Footer>{footerItems}</Footer>}
        </Kpi>
    );
}

export default KpiContainer;
