import { ActiveSubscription } from 'types/subscription';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

export type UseActiveSubscriptionsResponse = {
    activeSubscriptions?: ActiveSubscription[];
    isLoading: boolean;
    error?: ApiError;
};

type ActiveSubscriptionsResult = {
    activeSubscriptions: ActiveSubscription[];
};

type UseActiveSubscriptionsProps = {
    parentBusinessId: string;
    businessIds?: string[];
};

const useActiveSubscriptions = ({
    parentBusinessId,
    businessIds,
}: UseActiveSubscriptionsProps): UseActiveSubscriptionsResponse => {
    const { get } = useApi<ActiveSubscriptionsResult>();

    const urlParams = new URLSearchParams([['businessId', businessIds?.join(',') ?? '']]).toString();
    const url = `${GENERAL_BFF_PREFIX}/v1/businesses/${parentBusinessId}/active-subscriptions?${urlParams}`;
    const fetcher: () => Promise<ActiveSubscriptionsResult> = () => get(url);
    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery<ActiveSubscriptionsResult, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        enabled: !!parentBusinessId,
    });

    return {
        activeSubscriptions: data?.activeSubscriptions,
        isLoading,
        error: error ?? undefined,
    };
};

export default useActiveSubscriptions;
