import * as yup from 'yup';
import { Divider } from 'components/elements/Divider';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { readingWidth, spaceS } from 'styles/variables';
import Checkbox from 'components/forms/Checkbox';
import LinkText from 'components/clickables/LinkText';
import styled from 'styled-components';
import useBUContent from 'hooks/useBUContent';

const StyledCheckbox = styled(Checkbox)`
    max-width: ${readingWidth};
`;

const Paragraph = styled.p`
    margin-bottom: ${spaceS};
    padding-top: ${spaceS};
    max-width: ${readingWidth};
`;

export type ConsentFormProps = {
    termsAndConditionsRegister: UseFormRegisterReturn;
    termsAndConditionsError?: FieldError;
    termsAndConditionsChecked: boolean;
    hidePrivacyPolicy?: boolean;
};

export const ConsentFormSchema = yup.object().shape({
    termsAndConditionsConsent: yup
        .boolean()
        .required('form.input.termsAndConditionsConsent.required')
        .oneOf([true], 'form.input.termsAndConditionsConsent.required'),
});

function ConsentForm({
    termsAndConditionsRegister,
    termsAndConditionsError,
    termsAndConditionsChecked = false,
    hidePrivacyPolicy = false,
}: ConsentFormProps): JSX.Element {
    const { buContent } = useBUContent();

    return (
        <>
            {hidePrivacyPolicy ? null : (
                <Paragraph>
                    <Trans
                        i18nKey="general.privacyPolicy"
                        components={{
                            link1: <LinkText to={buContent.privacyPolicyLink} />,
                        }}
                    />
                </Paragraph>
            )}
            <Divider />
            <StyledCheckbox
                required
                dataTestId="termsAndConditionsConsent"
                label={
                    <Trans
                        i18nKey="form.input.termsAndConditionsConsent.label"
                        components={{
                            link1: <LinkText to={buContent.termsOfSalesLink} />,
                        }}
                    />
                }
                checked={termsAndConditionsChecked}
                fieldError={termsAndConditionsError}
                {...termsAndConditionsRegister}
            />
        </>
    );
}

export default ConsentForm;
