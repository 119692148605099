import * as yup from 'yup';
import { ButtonType, ButtonVariant, EToastType } from 'types';
import { ClickableListItem, ListItem } from 'components/lists/DetailsList';
import { EBusinessFeature } from 'types/business';
import { ESubscriptionOrderStatus, SubscriptionOrder } from 'types/subscription';
import { ETrackingAction, ETrackingEvent, ETrackingOrigin } from 'types/tracking';
import { EditDetailsSection } from 'components/elements/EditDetailsSection';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import { SubmitHandler, useForm } from 'react-hook-form';
import { borderRadiusM, licencePlateInputWidth, panelBackgroundColorGrey, spaceL, spaceS } from 'styles/variables';
import { formatDate } from 'utils/formatDate';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useEffect, useState } from 'react';
import { useToast } from 'contexts/Toast';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import Form from 'components/forms/Form';
import ReactGA from 'react-ga4';
import StatusTag from 'components/visuals/StatusTag';
import SubscriptionOrderStatusPopUp from '../SubscriptionOrderStatusPopUp';
import TextInput from 'components/forms/TextInput';
import styled from 'styled-components';
import useAuthorization from 'hooks/useAuthorization';
import useSubscriptionOrder from 'hooks/useSubscriptionOrder';

const Container = styled.div`
    border-radius: ${borderRadiusM};
    padding: ${spaceL} 1.25rem;
    background-color: ${panelBackgroundColorGrey};
`;

const ButtonContainer = styled.section`
    display: flex;
    gap: ${spaceS};
    margin-top: ${spaceS};
`;

const LicencePlateInput = styled(TextInput)`
    max-width: ${licencePlateInputWidth};
`;

type SubscriptionOrderDetailsPanelProps = {
    selectedOrder: SubscriptionOrder;
    sidePanelOpen: boolean;
    onSave: () => void;
};

const UpdateSubscriptionSchema = (
    licencePlateRegExp: RegExp,
): yup.ObjectSchema<Pick<SubscriptionOrder, 'licencePlate' | 'reference'>> =>
    yup.object().shape({
        licencePlate: yup
            .string()
            .required('form.input.licencePlate.required')
            .matches(licencePlateRegExp, { message: 'form.input.licencePlate.validation' }),
        reference: yup.string().required('form.input.reference.required'),
    });

function SubscriptionOrderDetailsPanel({
    selectedOrder,
    sidePanelOpen,
    onSave,
}: SubscriptionOrderDetailsPanelProps): JSX.Element {
    const { t } = useTranslation();
    const { addToast } = useToast();

    const [editField, setEditField] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (!sidePanelOpen) {
            setEditField(undefined);
        }
    }, [sidePanelOpen]);

    const { subscriptionOrder, isLoading, patchSubscriptionOrder, error } = useSubscriptionOrder({
        businessId: selectedOrder.businessId,
        orderId: selectedOrder.id,
    });

    if (error) {
        addToast({ message: t('subscription.details.errorMessage'), type: EToastType.ERROR });
    }

    useEffect(() => {
        if (selectedOrder.id !== subscriptionOrder?.id) {
            setEditField(undefined);
        }
    }, [selectedOrder, subscriptionOrder]);

    const { checkAuthorizationFeature } = useAuthorization();
    const editAllowed =
        checkAuthorizationFeature(EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT) &&
        subscriptionOrder?.status === ESubscriptionOrderStatus.PENDING;

    const { licencePlateRegExp, locale, licencePlateMaxLength, licencePlateMinLength } = useCountrySpecificContent();

    const formMethods = useForm<Partial<SubscriptionOrder>>({
        mode: 'onSubmit',
        resolver: yupResolver(
            UpdateSubscriptionSchema(licencePlateRegExp) as yup.ObjectSchema<Partial<SubscriptionOrder>>,
        ),
        shouldUnregister: false,
        defaultValues: {
            licencePlate: subscriptionOrder?.licencePlate,
            reference: subscriptionOrder?.reference,
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors, dirtyFields },
        reset,
        clearErrors,
    } = formMethods;

    const onSubmit: SubmitHandler<Partial<SubscriptionOrder>> = async (data): Promise<void> => {
        ReactGA.event(ETrackingEvent.EDIT, {
            origin: ETrackingOrigin.SUBSCRIPTION_ORDER_DETAILS,
            action: ETrackingAction.SUBMIT,
        });
        try {
            if (dirtyFields.licencePlate || dirtyFields.reference) {
                const patchData = dirtyFields.licencePlate
                    ? { licencePlate: data.licencePlate }
                    : { reference: data.reference };

                await patchSubscriptionOrder.mutateAsync(patchData);
                addToast({ message: t('subscription.details.successMessage'), type: EToastType.SUCCESS });
                onSave();
            }
            setEditField(undefined);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.temporary) {
                    addToast({ message: t('general.errorToast'), type: EToastType.ERROR });
                } else {
                    addToast({ message: t('subscription.details.errorMessage'), type: EToastType.ERROR });
                }
            }
        }
    };
    const onCancel = (): void => {
        clearErrors();
        reset({
            licencePlate: subscriptionOrder?.licencePlate,
            reference: subscriptionOrder?.reference,
        });
        setEditField(undefined);
        ReactGA.event(ETrackingEvent.EDIT, {
            origin: ETrackingOrigin.SUBSCRIPTION_ORDER_DETAILS,
            action: ETrackingAction.CANCEL,
        });
    };

    const handleEditClick = (field: string): void => {
        reset({
            licencePlate: subscriptionOrder?.licencePlate,
            reference: subscriptionOrder?.reference,
        });
        if (editField === field) {
            setEditField(undefined);
        } else {
            setEditField(field);
        }
    };

    return (
        <>
            {!subscriptionOrder && isLoading && <LoadingIndicator displayBorder={false} displayText={false} />}
            {subscriptionOrder && (
                <Container>
                    <ul>
                        <ListItem
                            title={t('general.status')}
                            value={
                                <StatusTag
                                    status={subscriptionOrder.status}
                                    infoButton={
                                        <SubscriptionOrderStatusPopUp
                                            gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION_ORDER_DETAILS}
                                        />
                                    }
                                />
                            }
                        />
                        {!subscriptionOrder.unknownDriver && (
                            <>
                                <ListItem
                                    title={t('general.name')}
                                    value={`${subscriptionOrder.driver?.firstName} ${subscriptionOrder.driver?.lastName}`}
                                />
                                <ListItem title={t('general.email')} value={subscriptionOrder.driver?.email} />
                            </>
                        )}
                        {editAllowed ? (
                            <ClickableListItem
                                dataTestId="editButton_licencePlate"
                                onClick={() => {
                                    handleEditClick('licencePlate');
                                }}
                                title={t('general.licencePlate')}
                                text={subscriptionOrder.licencePlate}
                                disabled={patchSubscriptionOrder.isPending}
                            />
                        ) : (
                            <ListItem title={t('general.licencePlate')} value={subscriptionOrder.licencePlate} />
                        )}
                        {editAllowed ? (
                            <ClickableListItem
                                dataTestId="editButton_reference"
                                onClick={() => {
                                    handleEditClick('reference');
                                }}
                                title={t('general.reference')}
                                text={subscriptionOrder.reference}
                                disabled={patchSubscriptionOrder.isPending}
                            />
                        ) : (
                            <ListItem title={t('general.reference')} value={subscriptionOrder.reference} />
                        )}

                        <ListItem
                            title={t('general.creationDate')}
                            value={formatDate(subscriptionOrder.creationTime, locale)}
                        />
                        <ListItem title={t('general.subscription')} value={subscriptionOrder.name} />
                        <ListItem title={t('general.organisation')} value={subscriptionOrder.businessName} />
                    </ul>
                    {editAllowed && !!editField && (
                        <EditDetailsSection>
                            <Form formMethods={formMethods} onSubmit={handleSubmit(onSubmit)}>
                                {editField === 'licencePlate' && (
                                    <LicencePlateInput
                                        maxLength={licencePlateMaxLength}
                                        minLength={licencePlateMinLength}
                                        label={t('form.input.licencePlate.label')}
                                        required
                                        fieldError={errors.licencePlate}
                                        {...register('licencePlate', { required: true })}
                                    />
                                )}
                                {editField === 'reference' && (
                                    <TextInput
                                        maxLength={25}
                                        required
                                        label={t('form.input.reference.label')}
                                        fieldError={errors.reference}
                                        {...register('reference', { required: true })}
                                    />
                                )}
                                <ButtonContainer>
                                    <Button
                                        dataTestId="subscriptionOrderDetailsPanelCancel"
                                        type={ButtonType.BUTTON}
                                        variant={ButtonVariant.SECONDARY}
                                        onClick={onCancel}
                                        disabled={patchSubscriptionOrder.isPending}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        dataTestId="subscriptionOrderDetailsPanelSave"
                                        type={ButtonType.SUBMIT}
                                        isLoading={patchSubscriptionOrder.isPending}
                                    >
                                        {t('general.save')}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        </EditDetailsSection>
                    )}
                </Container>
            )}
        </>
    );
}

export default SubscriptionOrderDetailsPanel;
